
import {defineComponent} from 'vue'
import {CALL_CATEGORY} from "@/core/config/Constant";
import Row from "@/components/base/common/Row.vue";
import {LoadPanel} from "@/core/composite/composite";
import CallCategory from "@/views/activity/CallCategory.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import CallLength from "@/views/activity/CallLength.vue";

export default defineComponent({
  name: "CallCard",
  components: {CallLength,   Avatar, CallCategory, Row,},
  props: {
    activity: {
      required: true, type: Object
    }
  },
  setup() {
    return {
      CALL_CATEGORY,
      ...LoadPanel(),

    }
  },
  methods: {
    buildName(category) {
      console.log(category)
      if (!category) return ''
      return this.CALL_CATEGORY.find(item => item.value === category)?.name
    }
  }
})
