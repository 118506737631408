
import { computed, defineComponent } from "vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import store from "@/store";

export default defineComponent({
  name: "Entities",
  components: {EntityLoading},
  props: {
    label: {type: String, default: 'Items'},
    items: {type: Number, default: 0},
    viewCard: {type: Boolean, default: false},
    viewTable: {type: Boolean, default: false},
    enableFilter: {type: Boolean, default: false},
    displayView: {type: Boolean, default: true},
    showLabel: {type: Boolean, default: true},
  },
  setup() {
    const loading = computed<boolean>(() => store.state.LoadingModule.searchLoading)
    return {
      loading,
    }
  }
})
