
import {computed, defineComponent, onMounted, ref} from 'vue'
import store from "@/store";
import {ClientSelect, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Entities from "@/components/base/common/Entities.vue";
import RecentCards from "@/components/base/common/RecentCards.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import CallCard from "@/views/assistance/call-center/CallCard.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import DateTimeSelect from "@/components/base/datetime/DateTimeSelect.vue";
import {useToast} from "vue-toast-notification";

export default defineComponent({
  name: "Search",
  components: {DateTimeSelect, BaseSelect, UserListSelect, CallCard, RecentCards, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams('Phone Calls',
        [
          {link: false, router: '', text: 'Phone Calls'}
        ]
      )
    })
    const objectFilter = ref({
      phoneCall: true,
      clientId: '',
      policyNumber: '',
      claimNumber: '',
      cazeNumber: '',
      managerId: '',
      dateFilter: '',
      noAudio: false,
      auditStatus: '',
      dateFrom: '',
      dateTo: '',
    })
    const page = computed(() => store.state.ActivityModule.searchPage);
    return {
      ...ClientSelect(false),
      objectFilter,
      ...LoadFilterObjects(Actions.ACTIVITY_SEARCH_PAGE, objectFilter.value, ['client', 'policy', 'claim', 'caze', 'user']),
      page,
    }
  },
  methods: {
    onDiscard() {
      this.objectFilter = {
        phoneCall: true,
        clientId: '',
        policyNumber: '',
        claimNumber: '',
        cazeNumber: '',
        managerId: '',
        dateFilter: '',
        noAudio: false,
        auditStatus: '',
        dateFrom: '',
        dateTo: '',
      }
      this.paginationLoad()
    },
    onSearch() {
      if (this.objectFilter.dateFilter == 'CUSTOM') {
        if (this.objectFilter.dateFrom ===''
          || this.objectFilter.dateFrom == ''
          || this.objectFilter.dateFrom == undefined
          || this.objectFilter.dateTo == undefined ) {
          useToast().warning("Please select Date From and To", {position: 'top-right'})
          return
        }
      }else {
        this.objectFilter.dateFrom = ''
        this.objectFilter.dateTo = ''
      }
      this.updateFilterObject(this.objectFilter)
    }
  }
})
