
import {defineComponent, ref, watch} from 'vue'
import {CALL_CATEGORY} from "@/core/config/Constant";

export default defineComponent({
  name: "CallCategory",
  props:{
    category:{}
  },
  setup(props) {
    const name = ref<any>('');
    const build = (category)=>{
      if (category) {
        const cat = CALL_CATEGORY.find(item=>item.value === category);
        name.value = cat?.name
      }else{
        name.value = ''
      }
    }
    build(props.category)
    watch(()=>props.category, cb=> {
      build(cb)
    })
    return {
      name
    }
  }
})
